import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Alert from '@mui/lab/Alert';
import { Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as pdfjs from "pdfjs-dist";
import { PDFDocumentProxy } from 'pdfjs-dist/types/display/api';
import { FormDataContext } from '../../../components/FormDataContext';
import {DropZone, ResumeUploader} from "pages/create/steps/step-1/update/UpdateUpload.styles";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UpdateUpload = () => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [fileName, setFilename] = useState("");
    const formDataContext = useContext(FormDataContext);

    const handleUpload = (files: File[]) => {
        const file = files[0];
        setFilename(file.name);
        const fileReader = new FileReader();
        fileReader.onload = function() {
            const typedArray = new Uint8Array(this.result as ArrayBufferLike);
            pdfjs.getDocument(typedArray).promise.then((pdf: PDFDocumentProxy) => {
                pdf.getMetadata().then(({info}) => {
                    const resumeMeta = (info as any).Custom.OpenResume;
                    const openResume = (atob(resumeMeta));
                    setError("");
                    formDataContext.setProgressionDisabled(false);
                    formDataContext.setFormData({details: JSON.parse(openResume)});
                    setSuccess(true);
                }).catch((error: string) => {
                    console.error("Failed to find OpenResume metadata: " + error);
                    setError("It looks like this PDF file isn't an OpenResume.");
                    setSuccess(false);
                    formDataContext.setProgressionDisabled(true)
                });
            }).catch((error: string) => {
                console.error("Failed to read PDF file: " + error)
                setError("It looks like this PDF is corrupt, or in an unusual PDF format.");
            });
        };
        fileReader.readAsArrayBuffer(file);
    }

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: 'application/pdf',
        maxFiles: 1,
        onDrop: handleUpload
    });

    return (
        <ResumeUploader>
            <DropZone {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <Icon>cloud_upload</Icon>
                <p>Drag and drop your OpenResume here, or click to select file</p>
                <em>(Only PDF files will be accepted)</em>
            </DropZone>
            <aside>
                { error &&
                    <Alert severity="error">
                        Could not upload <strong>{fileName}</strong>. {error} <Link to="/faqs#errors">Learn more</Link>
                    </Alert>
                }
                { success &&
                    <Alert severity="success">
                        <strong>{fileName}</strong> has been uploaded successfully. Click 'Next' to continue.
                    </Alert>
                }
            </aside>
        </ResumeUploader>
    )
}

export default UpdateUpload;
